import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import useAxiosPrivate from '../../../hooks/useAxiosPrivate';
import { toast } from 'sonner';
import { Input } from '../../ui/input';
import { Icons } from '../../../assets/icons';
import { Label } from '../../ui/label';
import { useGetOtp, useVerifyUser } from '../../../helpers/api/useAuth';
import React from 'react';
import {
  OnboardContext,
  OnboardContextType,
} from '../../../context/OnboardContext';
import {
  GlobalContext,
  GlobalContextType,
} from '../../../context/GlobalContext';
import { useQueryClient } from '@tanstack/react-query';
import { useCookies } from 'react-cookie';
import { SectionEnum } from '../../../pages/register/register';
import { routes } from '../../../utils/route';

const Verification = ({ next }: { next: (arg0: SectionEnum) => void }) => {
  const cookies = useCookies()[0];
  const location = useLocation();
  const queryClient = useQueryClient();
  const { dispatch } = useContext(OnboardContext) as OnboardContextType;
  const { users } = useContext(GlobalContext) as GlobalContextType;
  const user = users;
  const { isEmailConfirmed, isPhoneNumberConfirmed } = user ?? {};
  const axiosPrivate = useAxiosPrivate();
  const [verification, setVerification] = useState({
    email: '',
    phone: '',
  });
  const [section, setSection] = useState<string>(
    location.state?.subsection || 'phone'
  );
  const navigate = useNavigate();
  const {
    data,
    isError,
    isLoading: verifying,
    isSuccess,
    mutate,
    errorMessage,
  } = useVerifyUser();

  const getOtp = useGetOtp();
  const getCode = async () => {
    if (process.env.REACT_APP_ENV === 'development') {
      try {
        await axiosPrivate.get(`user/temp-otp/${cookies?.musha_email}`);
      } catch (err: any) {
        console.error(
          err.response?.data?.responseMessage ?? 'An error occurred'
        );
      }
    }
  };

  const handleChange = (e: any) => {
    setVerification((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const HandleVerification = async (e: any) => {
    e.preventDefault();
    let payload = {
      email: cookies?.musha_email,
      otp:
        section.toLowerCase() === 'phone'
          ? verification.phone
          : verification.email,
    };
    mutate(payload);
  };

  useEffect(() => {
    if (isSuccess) {
      queryClient.cancelQueries({
        queryKey: ['get-user-details', cookies?.musha_userId],
      });
      queryClient.setQueryData(
        ['get-user-details', cookies?.musha_userId],
        data
      );
      if (data?.data?.accountStatus?.toLowerCase() === 'active') {
        navigate(routes.profile);
        dispatch({ type: 'REFETCH_USER_DETAILS', payload: true });
      } else {
        setVerification({
          email: '',
          phone: '',
        });
        if (
          section.toLowerCase() === 'phone' &&
          data?.data?.isPhoneConfirmed === true
        ) {
          setSection('email');
        } else if (data?.data?.isEmailConfirmed === true) {
          dispatch({ type: 'REFETCH_USER_DETAILS', payload: true });
          next(SectionEnum.RequiredSteps);
        } else {
          setSection('email'); // Or you can set it to 'phone' if preferred
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      toast.error(errorMessage || `Verification fail`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  if (isEmailConfirmed && isPhoneNumberConfirmed) {
    return <Navigate to={routes.dashboard} replace={true} />;
  }

  return (
    <form
      autoComplete="off"
      onSubmit={HandleVerification}
      className="flex flex-col w-full max-w-sm gap-4 mx-auto"
    >
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Verify Your Account</h4>
        <p className="text-[15px]">Let's get your account verified</p>
      </div>

      {section === 'phone' ? (
        <div className="relative flex flex-col gap-1.5">
          <Label htmlFor="phone"> Phone Number Verification</Label>
          <Input
            id="phone"
            type="phone"
            value={verification.phone}
            placeholder="Enter code"
            className="h-12"
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            endIcon={
              <div
                onClick={() => {
                  getCode();
                  toast.promise(
                    getOtp.mutateAsync({
                      otpType: 'Phone',
                    }),
                    {
                      loading: 'Sending otp...',
                      success: 'Otp sent successfully',
                      error: `An error occurred while sending otp`,
                    }
                  );
                }}
              >
                <p className="text-sm cursor-pointer">Get Code</p>
              </div>
            }
          />
        </div>
      ) : (
        <div className="relative  flex flex-col gap-1.5">
          <Label htmlFor="phone">Email Verification</Label>
          <Input
            id="email"
            type="text"
            placeholder="Enter code"
            className="h-12"
            autoComplete="off"
            value={verification.email}
            onChange={(e) => handleChange(e)}
            endIcon={
              <div
                onClick={() => {
                  getCode();
                  toast.promise(
                    getOtp.mutateAsync({
                      otpType: 'Email',
                    }),
                    {
                      loading: 'Sending otp...',
                      success: 'Otp sent successfully',
                      error:
                        getOtp.error || 'An error occurred while sending otp',
                    }
                  );
                }}
              >
                <p className="text-sm cursor-pointer">Get Code</p>
              </div>
            }
          />
        </div>
      )}
      <button
        type="submit"
        disabled={
          (section === 'email' && (verifying || verification?.email === '')) ||
          (section === 'phone' && (verifying || verification?.phone === ''))
        }
        className="bg-primary inline-flex items-center justify-center w-full p-3 text-sm font-medium text-white transition-colors rounded-lg whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-[#8C93B4]"
      >
        {!verifying ? (
          'Submit'
        ) : (
          <Icons.Spinner className="w-6 h-6 animate-spin" />
        )}
      </button>
    </form>
  );
};

export default Verification;
