import { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Profile from '../../components/dashboard/Profile';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../../components/ui/dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '../../components/ui/button';
import { useNavigate } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { useGetOtp, useLogout, useVerifyUser } from '../../helpers/api/useAuth';
import { useGetUser } from '../../helpers/api/useAsset';
import { toast } from 'sonner';
import { Label } from '../../components/ui/label';
import { Input } from '../../components/ui/input';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';
import ContainerLayout from '../../components/core/containerLayout';

const ProfilePage = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const { payload, isLoading, isError, errorMessage, refetch } = useGetUser();
  const user = payload;
  const [is2fa, setIs2fa] = useState<boolean>(
    user?.is2faEnabled === true ? true : false
  );
  const [otp, setOtp] = useState<string>('');
  const [step, setStep] = useState<number>(1);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [accountForm, setAccountForm] = useState({
    phone: user?.phone,
    timeZone: user?.timeZone,
  });

  const getOtp = useGetOtp();

  const {
    isError: verificationError,
    isLoading: verifying,
    isSuccess,
    mutate,
    errorMessage: verificationErrorMessage,
  } = useVerifyUser();

  useEffect(() => {
    if (isSuccess) {
      toast.success('Phone changed successfully');
      setShow(false);
      setStep(1);
      setOtp('');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (verificationError) {
      toast.error(verificationErrorMessage || `Verification fail`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verificationError]);

  const handleChange = (e: any) => {
    setOtp(e.target.value);
  };

  const HandleVerification = async (e: any) => {
    e.preventDefault();
    let payload = {
      email: user?.email,
      otp: otp,
    };
    mutate(payload);
  };

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (user) {
      setIs2fa(user?.is2faEnabled === true ? true : false);
    }
  }, [user]);

  const set2fa = async (value: boolean) => {
    try {
      // Update the state
      setIs2fa(value);

      if (value) {
        await axiosPrivate.post(`user/enable-2fa`);
      } else {
        await axiosPrivate.post(`user/disable-2fa`);
      }
    } catch (err: any) {
      // Store the current value before making changes
      let previousIs2fa = is2fa;
      // If the API call fails, revert the state back to the previous value
      setIs2fa(previousIs2fa);

      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  const update = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      await axiosPrivate.patch(`user/user`, accountForm);
      setLoading(false);
      setStep(2);
    } catch (err: any) {
      setLoading(false);
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  const { logoutUser: logout } = useLogout();

  if (isLoading) {
    return (
      <div className="flex justify-center mt-10">
        <Icons.Spinner />
      </div>
    );
  }

  if (isError) {
    return <ErrorMessage message={errorMessage} refetch={refetch} />;
  }

  return (
    <ContainerLayout className="gap-0 py-6">
      <div className="hidden w-full px-4 py-2 md:px-0">
        <Button
          id="top"
          variant="ghost"
          className="p-0 text-sm md:text-base"
          onClick={goBack}
        >
          <FontAwesomeIcon icon={faAngleLeft} /> Back
        </Button>
      </div>
      <Profile
        user={user}
        set2Fa={set2fa}
        is2fa={is2fa}
        handleShow={() => setShow(true)}
        logout={logout}
      />
      <Dialog
        open={show}
        onOpenChange={() => {
          setShow(false);
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Update Your Account Details</DialogTitle>
          </DialogHeader>

          {step === 1 ? (
            <div>
              <Label htmlFor="phone">Phone Number</Label>
              <Input
                id="phone"
                type="text"
                defaultValue={user?.phone}
                onChange={(e) =>
                  setAccountForm((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }))
                }
              />
            </div>
          ) : (
            <div className="relative flex flex-col gap-1.5">
              <Label htmlFor="phone">Verify phone number</Label>
              <Input
                id="phone"
                type="phone"
                value={otp}
                placeholder="Enter code"
                className="h-12"
                autoComplete="off"
                onChange={(e) => handleChange(e)}
                endIcon={
                  <div
                    onClick={() => {
                      toast.promise(
                        getOtp.mutateAsync({
                          otpType: 'Phone',
                        }),
                        {
                          loading: 'Sending otp...',
                          success: 'Otp sent successfully',
                          error:
                            getOtp.error ||
                            'An error occurred while sending otp',
                        }
                      );
                    }}
                  >
                    <p className="text-sm cursor-pointer">Get Code</p>
                  </div>
                }
              />
            </div>
          )}

          <DialogFooter className="flex items-center justify-between w-full gap-3 mt-auto">
            <Button
              className="p-3.5 h-12 text-sm rounded-lg w-full"
              disabled={verifying}
              variant="secondary"
              type="button"
              onClick={(e) => setShow(false)}
            >
              Close
            </Button>
            <Button
              disabled={
                loading ||
                verifying ||
                (step === 2 && otp.length < 6) ||
                user?.phone === accountForm?.phone
              }
              className={cn(
                'rounded-lg text-sm w-full font-medium disabled:opacity-50 outline-none p-3.5 h-12 flex items-center justify-center'
              )}
              type="button"
              onClick={(e) => {
                if (step === 1) {
                  update(e);
                } else {
                  HandleVerification(e);
                }
              }}
            >
              {loading || verifying ? (
                <Icons.Spinner className="w-6 h-6 animate-spin" />
              ) : (
                'Update'
              )}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </ContainerLayout>
  );
};

export default ProfilePage;
