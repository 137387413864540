import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AssetCard from '../ui/asset-card';
import SkeletonCard from '../ui/skeleton-card';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/button';
import React from 'react';

const SavedAsset = ({
  assets,
  isLoading,
}: {
  assets: any;
  isLoading: boolean;
}) => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="p-0 md:p-10 dark:p-0 dark:border-[#3b3a3a] dark:border bg-[#F2F2F2] dark:bg-transparent rounded-[25px]">
      <div className="flex flex-col items-start md:min-h-[800px] gap-4 p-0 bg-white dark:bg-transparent md:p-6 rounded-3xl">
        <div className="flex items-center gap-4">
          <Button
            variant="ghost"
            className="p-0 bg-transparent cursor-pointer"
            onClick={goBack}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </Button>
          <h4 className="font-medium text-[#4D5D6A]">{'Saved Assets'}</h4>
        </div>
        <div className="flex flex-col w-full gap-4">
          {isLoading ? (
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-4">
                <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-3">
                  {Array(12)
                    .fill(0)
                    .map((_, i) => (
                      <SkeletonCard key={i} />
                    ))}
                </div>
              </div>
            </div>
          ) : (
            assets?.length > 0 && (
              <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-3">
                {assets.map((asset: any, j: number) => (
                  <AssetCard key={j} asset={asset ?? []} />
                ))}
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default SavedAsset;
