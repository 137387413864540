import Login from '../../components/auth/Login';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../../components/core/logo';
import { getAuthToken } from '../../helpers/axiosConfig';
import { useEffect } from 'react';
import React from 'react';
import { routes } from '../../utils/route';

const LoginPage = () => {
  const token = getAuthToken();
  const navigate = useNavigate();

  useEffect(() => {
    if (token) {
      return navigate(routes.dashboard);
    }
  }, [navigate, token]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen max-w-sm gap-3 px-4 mx-auto">
      <Link to="/">
        <Logo type="icon" />
      </Link>
      <Login />
    </div>
  );
};

export default LoginPage;
