import { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Logo from '../../components/core/logo';
import CreateAccount from '../../components/auth/createAccount/Register';
import RequiredSteps from '../../components/auth/createAccount/RequiredSteps';
import ProfilePicture from '../../components/auth/createAccount/ProfilePicture';
import Verification from '../../components/auth/createAccount/Verification';
import ChangePassword from '../../components/auth/createAccount/ChangePassword';
import ForgotPassword from '../../components/auth/createAccount/ForgotPassword';
import FileUpload from '../../components/auth/createAccount/FileUpload';
import { toast } from 'sonner';
import React from 'react';
import { getBase64 } from '../../utils/helper';

export enum SectionEnum {
  Register = 'register',
  Verification = 'verification',
  ChangePassword = 'changePassword',
  ForgotPassword = 'forgotPassword',
  RequiredSteps = 'requiredSteps',
  ProfilePicture = 'profilePicture',
  FileUpload = 'fileUpload',
}

const Register = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const [reqDocuments, setReqDocuments] = useState([]);
  const [optionalDoc, setOptionalDoc] = useState([]);
  const [requiredOptional, setRequiredOptional] = useState<number>(0);
  const [requiredNo, setRequiredNo] = useState<number>(0);
  const [section, setSection] = useState<SectionEnum>(SectionEnum.Register);
  const getRequiredDocuments = useCallback(async () => {
    try {
      let requiredDocuments = await axiosPrivate.get(
        'user/verification-required-documents'
      );
      let optional = requiredDocuments.data.data.documents.filter(
        (item: { isRequired: any }) => !item.isRequired
      );
      setOptionalDoc((prev) => optional);
      let required = requiredDocuments.data.data.documents.filter(
        (item: { isRequired: any }) => item.isRequired
      );
      setReqDocuments((prev) => required);
      requiredDocuments.data.data.documents.forEach(
        (item: { isRequired: any }) => {
          if (item.isRequired) {
            setRequiredNo((prev) => prev + 1);
          }
        }
      );
      setRequiredOptional(
        (prev) => requiredDocuments.data.data.requiredOptional
      );
    } catch (err: any) {
      toast.error('Error fetching required documents');
    }
  }, [axiosPrivate]);

  useEffect(() => {
    if (
      location?.state?.section &&
      Object.values(SectionEnum).includes(location?.state?.section)
    ) {
      setSection(location.state.section as SectionEnum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [section]);

  useEffect(() => {
    if (
      section &&
      section === 'requiredSteps' &&
      !reqDocuments.length &&
      !optionalDoc.length
    )
      getRequiredDocuments();
  }, [getRequiredDocuments, optionalDoc.length, reqDocuments.length, section]);

  const next = (id: SectionEnum): void => {
    setSection(id);
  };

  return (
    <div className="flex flex-col items-center justify-center max-w-[459px] gap-3 px-4 pb-20 mx-auto mt-20">
      <Link to="/">
        <Logo type="icon" />
      </Link>
      {section === 'register' && <CreateAccount next={next} />}
      {section === 'verification' && <Verification next={next} />}
      {section === 'changePassword' && <ChangePassword />}
      {section === 'forgotPassword' && <ForgotPassword />}
      {section === 'requiredSteps' && (
        <RequiredSteps
          next={next}
          optionalDoc={optionalDoc}
          reqDocuments={reqDocuments}
          requiredOptional={requiredOptional}
          requiredNo={requiredNo}
        />
      )}
      {section === 'profilePicture' && (
        <ProfilePicture next={next} getBase64={getBase64} />
      )}
      {section === 'fileUpload' && (
        <FileUpload next={next} getBase64={getBase64} />
      )}
      {/* <div id="moreSteps" className="section">
          <MoreSteps next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectAsset next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectPrice next={next} />
        </div>
        <div id="selectedAsset" className="section">
          <SelectState next={next} />
        </div> */}
    </div>
  );
};

export default Register;
