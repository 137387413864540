import { useNavigate, useSearchParams } from 'react-router-dom';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import AssetCard from '../ui/asset-card';
import { Button } from '../ui/button';
import React from 'react';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../ui/tabs';
import SkeletonCard from '../ui/skeleton-card';
import { Icons } from '../../assets/icons';
import ContainerLayout from '../core/containerLayout';

interface MyAssetProps {
  firstAsset: any;
  assets: any;
  isLoading: boolean;
  setTab: (arg0: { category: number }) => void;
}

const MyAsset: React.FC<MyAssetProps> = ({ assets, isLoading, setTab }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [category, setCategory] = useState(searchParams.get('view') || 'Sold');

  const goBack = () => {
    navigate(-1);
  };

  const handleTabChange = (value: string) => {
    setCategory(value);
    setSearchParams({ view: value });
  };

  // Use useEffect to trigger the mutation when setTab sets the category
  useEffect(() => {
    let categoryValue = 0;
    switch (category) {
      case 'UpForSale':
        categoryValue = 0;
        break;
      case 'Sold':
        categoryValue = 1;
        break;
      case 'Purchased':
        categoryValue = 2;
        break;
      default:
        categoryValue = 0;
    }
    setTab({ category: categoryValue });
  }, [category, setTab]);

  return (
    <ContainerLayout>
      <Button
        variant="ghost"
        className="absolute hidden p-0 bg-transparent cursor-pointer"
        onClick={goBack}
      >
        <FontAwesomeIcon icon={faAngleLeft} /> Back
      </Button>
      <Tabs
        value={category}
        onValueChange={handleTabChange}
        defaultValue="Sold"
        className="flex flex-col w-full gap-4"
      >
        <TabsList className="justify-start w-full h-12 px-0 bg-transparent border-none rounded-none dark:bg-transparent">
          <TabsTrigger
            value="Sold"
            className="hover:bg-primary/20 h-11 text-light-grey flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:font-medium data-[state=active]:shadow-none border-b border-[#D6DADF] data-[state=active]:border-b-2 data-[state=active]:border-primary dark:bg-transparent rounded-none"
          >
            Sold
          </TabsTrigger>
          <TabsTrigger
            value="Purchased"
            className="hover:bg-primary/20 h-11 flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:font-medium data-[state=active]:shadow-none text-light-grey border-b border-[#D6DADF] data-[state=active]:border-b-2 data-[state=active]:border-primary dark:bg-transparent rounded-none"
          >
            Purchased
          </TabsTrigger>
          <TabsTrigger
            value="UpForSale"
            className="hover:bg-primary/20 h-11 flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:font-medium data-[state=active]:shadow-none text-light-grey border-b border-[#D6DADF] data-[state=active]:border-b-2 data-[state=active]:border-primary dark:bg-transparent rounded-none"
          >
            Up-For-Sale
          </TabsTrigger>
        </TabsList>
        {['Sold', 'Purchased', 'UpForSale'].map((tabValue) => (
          <TabsContent key={tabValue} value={tabValue}>
            <div className="flex flex-col w-full gap-4">
              {isLoading ? (
                <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-3">
                  {Array(12)
                    .fill(0)
                    .map((_, i) => (
                      <SkeletonCard key={i} />
                    ))}
                </div>
              ) : assets?.length > 0 ? (
                <div className="grid grid-cols-2 gap-4 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 sm:grid-cols-3">
                  {assets.map((asset: any, j: number) => (
                    <AssetCard
                      key={j}
                      asset={asset ?? []}
                      showLike={false}
                      similar={tabValue}
                      isPurchased={
                        tabValue === 'Purchased' || tabValue === 'UpForSale'
                      }
                    />
                  ))}
                </div>
              ) : (
                <div className="flex flex-col items-center justify-center my-20 md:p-10 h-[600px]">
                  <Icons.EmptyTableIcon />
                  <p className="-mt-5 text-base">
                    {`There are no ${tabValue.toLowerCase()} assets`}
                  </p>
                </div>
              )}
            </div>
          </TabsContent>
        ))}
      </Tabs>
    </ContainerLayout>
  );
};

export default MyAsset;
