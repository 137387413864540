import './extPaymentPage.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Logo from '../../components/core/logo';
import * as animationData from '../../assets/error.json';
import Lottie from 'react-lottie';
import { Icons } from '../../assets/icons';
import { toast } from 'sonner';
import { routes } from '../../utils/route';

const ExtPaymentPage = () => {
  const navigate = useNavigate();
  const axiosPrivate = useAxiosPrivate();
  const [queryParameters] = useSearchParams();
  const [status, setStatus] = useState('');
  const [showSpinner, setShowSpinner] = useState<boolean>(true);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const fincraReferenceNumber = queryParameters.get('reference');
  const flutterRefNumber = queryParameters.get('tx_ref');
  // Determine refNumber based on the presence of fincraReferenceNumber or flutterRefNumber
  const refNumber = fincraReferenceNumber || flutterRefNumber;
  useEffect(() => {
    let timer1: NodeJS.Timeout, timer2: NodeJS.Timeout;

    if (status === 'invalid' || !status) {
      // Show spinner for 5 seconds
      timer1 = setTimeout(() => {
        setShowSpinner(false);
        setShowErrorMessage(true);

        // Redirect to dashboard after showing error message
        timer2 = setTimeout(() => {
          navigate(routes.dashboard);
        }, 5000); // Show the error message for 3 seconds
      }, 5000);
    }

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, [status, navigate]);

  useEffect(() => {
    if (refNumber) {
      paymentConfirmation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentConfirmation = async () => {
    try {
      let confirmation = await axiosPrivate.get(
        `asset/payment-confirmation/?reference=${refNumber}`
      );
      setStatus(confirmation.data.data.order.status);
    } catch (err: any) {
      setStatus('invalid');
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="flex flex-col items-center justify-center w-full h-screen max-w-xl p-4 mx-auto space-y-10">
      <div onClick={() => navigate('/')} className="absolute top-10">
        <Logo type="icon" />
      </div>

      <div className="relative z-30 text-center">
        {status &&
          (status.toLowerCase() === 'success' ||
            status.toLowerCase() === 'completed') && (
            <div className="flex flex-col items-center justify-center gap-4">
              <svg
                className="tick-svg"
                viewBox="0 0 26 26"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  fillRule="evenodd"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    className="circle"
                    d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"
                  />
                  <path
                    className="tick"
                    d="M6.5 13.5L10 17 l8.808621-8.308621"
                  />
                </g>
              </svg>
              <p className="text-xl font-medium">Payment Successful</p>
              <Link to={routes.dashboard} className="text-sm text-blue-500">
                Return to dashboard
              </Link>
            </div>
          )}
        {status && status.toLowerCase() === 'payment failed' && (
          <div className="flex flex-col items-center justify-center gap-4">
            <Lottie options={defaultOptions} height={150} width={150} />
            <p>Payment Failed</p>
          </div>
        )}
        {status &&
          status.toLowerCase() === 'pending transaction confirmation' && (
            <div className="flex flex-col items-center justify-center gap-4">
              <img className="" src="/assets/images/processing.png" alt="" />
              <p className="text-xl font-medium">
                We are currently confirming your payment
              </p>
              <Link to={routes.dashboard} className="text-sm text-blue-500">
                Return to dashboard
              </Link>
            </div>
          )}
        {status && status.toLowerCase() === 'invalid' && (
          <div className="flex flex-col items-center justify-center gap-4">
            <Lottie options={defaultOptions} height={150} width={150} />
            <p className="text-xl font-medium">Wrong Reference Number</p>
            <Link to={routes.dashboard} className="text-sm text-blue-500">
              Return to dashboard
            </Link>
          </div>
        )}
        {!status && (
          <div className="flex flex-col items-center justify-center gap-4">
            {showSpinner && (
              <>
                <Icons.IOSSpinner />
                <p className="text-xl font-medium">
                  Just a moment while we get your property ready...
                </p>
              </>
            )}
            {showErrorMessage && (
              <p className="text-base sm:text-lg">
                Sorry, payment was unsuccessful. Redirecting to the dashboard...
              </p>
            )}
          </div>
        )}
      </div>

      <div className="absolute bottom-0 right-0">
        <Icons.ExitPageBackground />
      </div>
    </div>
  );
};

export default ExtPaymentPage;
