import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import 'react-phone-input-2/lib/bootstrap.css';
import { useLocation } from 'react-router-dom';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../ui/tabs';
import React from 'react';
import CompanyForm from './companyForm';
import ContactForm from './contactForm';
import UserForm from './userForm';
import { SectionEnum } from '../../../../pages/register/register';

const CreateAccount = ({ next }: { next: (arg0: SectionEnum) => void }) => {
  const location = useLocation();
  let subsection = location.state?.subsection || null;

  const [isPersonalLoading, setPersonalLoading] = useState<boolean>(false);
  const [isCompanyLoading, setCompanyLoading] = useState<boolean>(false);
  const [isContactLoading, setContactLoading] = useState<boolean>(false);
  const [isContactForm, setContactForm] = useState<boolean>(false);
  const [accountType, setAccountType] = useState('User');

  useEffect(() => {
    if (subsection) {
      setContactForm(true);
      setAccountType('Corporate');
    }
  }, [subsection]);

  //corporate
  return (
    <div className="flex flex-col w-full max-w-md gap-3 mx-auto">
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Create Your Account</h4>
        <p>Let's get started</p>
      </div>

      <Tabs
        value={accountType}
        onValueChange={setAccountType}
        defaultValue="User"
        className="w-full space-y-5 "
      >
        <TabsList className="justify-start w-full h-14 px-0 border-none rounded-none bg-[#F9F9FF]">
          {!isContactForm ? (
            <>
              <TabsTrigger
                value="User"
                disabled={
                  isPersonalLoading || isCompanyLoading || isContactForm
                }
                className="hover:bg-primary h-14 hover:text-white text-[#8C93B4] flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:font-medium data-[state=active]:shadow-none data-[state=active]:border-b-2 border-primary rounded-none"
              >
                User account
              </TabsTrigger>
              <TabsTrigger
                value="Corporate"
                disabled={isPersonalLoading || isCompanyLoading}
                className="hover:bg-primary h-14 hover:text-white flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground  data-[state=active]:font-medium data-[state=active]:shadow-none text-[#8C93B4] data-[state=active]:border-b-2 border-primary rounded-none"
              >
                Company account
              </TabsTrigger>
            </>
          ) : (
            <TabsTrigger
              value=""
              className="flex gap-2 w-full data-[state=active]:bg-transparent data-[state=active]:text-foreground data-[state=active]:font-medium data-[state=active]:shadow-none text-[#8C93B4] data-[state=active]:border-b-2 border-primary rounded-none"
            >
              Company Representative Details
            </TabsTrigger>
          )}
        </TabsList>
        <TabsContent value="User">
          <UserForm
            next={next}
            isLoading={isPersonalLoading}
            setLoading={setPersonalLoading}
          />
        </TabsContent>
        <TabsContent value="Corporate">
          {isContactForm ? (
            <ContactForm
              next={next}
              isLoading={isContactLoading}
              setLoading={setContactLoading}
            />
          ) : (
            <CompanyForm
              toggleCompanyForm={setContactForm}
              isLoading={isCompanyLoading}
              setLoading={setCompanyLoading}
            />
          )}
        </TabsContent>
      </Tabs>
      <div className="flex-col gap-2 mt-3 ">
        <p className="text-sm text-center">
          Already have an account?{' '}
          <Link to="/login">
            <span className="text-blue-600">Login</span>
          </Link>{' '}
        </p>
      </div>
    </div>
  );
};

export default CreateAccount;
