import React from 'react';

const Logo = ({ type }: { type: string }) => {
  if (type === 'icon') {
    return <img className="w-12 h-18" src="/LOGO.png" alt="" />;
  } else if (type === "light") {
    return <img className="w-12 h-20" src="/musha-light-logo.png" alt="light-logo" />;
  } else {
    return (
      <div className="text-lg text-primary">
        <h2>Musha</h2>
      </div>
    );
  }
};

export default Logo;
