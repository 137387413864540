const Constants = {
    pendingEmailAndPhoneConfirmation: "PendingEmailAndPhoneConfirmation",
    pendingIdentityVerification: "PendingIdentityVerification",
    completeCorporateOnboarding: "CompleteCorporateOnboarding",
    identityVerificationDeclined: "IdentityVerificationDeclined",
    awaitingVerificationApproval: "AwaitingVerificationApproval",
    pendingPhoneConfirmation: "PendingPhoneConfirmation",
    firstTimeLogin: "FirstTimeLogin",
    active: 'Active', 
}

export default Constants

export const staticState = [
  {
    id: 1,
    name: 'Adamawa',
  },
  {
    id: 2,
    name: 'Akwa Ibom',
  },
  {
    id: 3,
    name: 'Anambra',
  },
  {
    id: 4,
    name: 'Ogun',
  },
  {
    id: 5,
    name: 'Ondo',
  },
  {
    id: 6,
    name: 'Rivers',
  },
  {
    id: 7,
    name: 'Bauchi',
  },
  {
    id: 8,
    name: 'Benue',
  },
  {
    id: 9,
    name: 'Borno',
  },
  {
    id: 10,
    name: 'Bayelsa',
  },
  {
    id: 11,
    name: 'Cross River',
  },
  {
    id: 12,
    name: 'Delta',
  },
  {
    id: 13,
    name: 'Ebonyi',
  },
  {
    id: 14,
    name: 'Edo',
  },
  {
    id: 15,
    name: 'Ekiti',
  },
  {
    id: 16,
    name: 'Enugu',
  },
  {
    id: 17,
    name: 'Federal Capital Territory',
  },
  {
    id: 18,
    name: 'Gombe',
  },
  {
    id: 19,
    name: 'Jigawa',
  },
  {
    id: 20,
    name: 'Oyo',
  },
  {
    id: 21,
    name: 'Imo',
  },
  {
    id: 22,
    name: 'Kaduna',
  },
  {
    id: 23,
    name: 'Kebbi',
  },
  {
    id: 24,
    name: 'Kano',
  },
  {
    id: 25,
    name: 'Kogi',
  },
  {
    id: 26,
    name: 'Osun',
  },
  {
    id: 27,
    name: 'Sokoto',
  },
  {
    id: 28,
    name: 'Plateau',
  },
  {
    id: 29,
    name: 'Taraba',
  },
  {
    id: 30,
    name: 'Yobe',
  },
  {
    id: 31,
    name: 'Zamfara',
  },
  {
    id: 32,
    name: 'Lagos',
  },
  {
    id: 33,
    name: 'Katsina',
  },
  {
    id: 34,
    name: 'Kwara',
  },
  {
    id: 35,
    name: 'Nasarawa',
  },
  {
    id: 36,
    name: 'Niger',
  },
  {
    id: 37,
    name: 'Abia',
  },
];

export const faqs = [
  {
    question: 'What is Musha?',
    answer:
      'Musha is a platform that enables average and low-income earners to invest in fractional units of real estate properties in West Africa. Our mission is to make property ownership more accessible, affordable, and transparent.',
  },
  {
    question: 'How does fractional ownership work?',
    answer:
      'Fractional ownership allows multiple investors to own a percentage of a property. Investors can buy fractional units in real estate projects or existing properties, becoming co-owners and earning rental income and/or returns proportional to their investment.',
  },
  {
    question: 'Who can invest through Musha?',
    answer:
      'Musha is open to all individuals who are interested in real estate investment, particularly those who find it challenging to access traditional property markets due to high costs.',
  },
  {
    question: 'How do I get started with Musha?',
    answer:
      'To get started, sign up on our website, complete your profile, and browse available properties in different asset classes. You can then choose a property to invest in and purchase fractional units of it.',
  },
  {
    question: 'What types of properties can I invest in?',
    answer:
      'Musha offers a variety of real estate asset classes, including residential, commercial, and land properties. Detailed information about each property is available on the it’s page.',
  },
  {
    question: 'How are returns on investment calculated?',
    answer:
      'Returns on investment come from rental income and potential property value appreciation. Rental income is distributed to investors based on their ownership percentage, and property value growth can be monitored in real time through our app.',
  },
  {
    question: 'What are the fees associated with investing on Musha?',
    answer:
      'Musha charges a small transaction fee currently 3% of investment value as against 5% charged by traditional agents. This helps us keep the platform running. This is outlined at the point of checkout. There are no hidden fees, and all costs are transparently displayed. If the fees change in the future we will notify our users.',
  },
  {
    question: 'Can I sell my shares?',
    answer:
      "Yes, Musha's marketplace feature allows investors to resell their units at any time. This creates a secondary market where you can liquidate your investment when needed. NB: This feature is not available at the moment. Users will be notified immediately it is made available.",
  },
  {
    question: 'How does Musha ensure the security of investments?',
    answer:
      'Musha employs advanced security measures, including encryption and secure payment gateways, to protect your investments and personal information. We also conduct thorough due diligence on all properties and companies listed on our platform.',
  },
  {
    question: "What happens if a property doesn't perform as expected?",
    answer:
      'While real estate investments carry risks, Musha strives to minimize these by selecting high-potential properties and providing accurate, AI-driven and professionally verified valuations. However, in the event of underperformance, investors may experience lower-than-expected returns.',
  },
  {
    question: 'How do I receive updates on my investments?',
    answer:
      'Investors receive regular updates and reports through the Musha app either via web and/or mobile, including rental income statements, property value assessments, and other relevant information.',
  },
  {
    question: 'Is there a minimum investment amount?',
    answer:
      'Yes, the minimum unit price varies by location and/or property. This information is provided in the investment details for each property.',
  },
  {
    question: 'How long is the investment period?',
    answer:
      'The investment period can vary depending on the property and the specific terms of the investment. This information is detailed in the investment prospectus for each property.',
  },
  {
    question: 'Can I visit the properties I invest in?',
    answer:
      'Yes, investors may have the opportunity to visit properties, depending on the specific terms and conditions of the investment and property type.',
  },
  {
    question: "What is Musha's refund policy?",
    answer:
      'All transactions on Musha are final and non-refundable. Refunds may only be issued upon a thorough review of individual cases.',
  },
  {
    question: 'How can I contact Musha for support?',
    answer:
      'For any questions or support, you can contact us at info@mushaapp.com or through the support section on our website.',
  },
  {
    question: 'How does Musha handle regulatory compliance?',
    answer:
      'Musha adheres to all relevant regulations and legal requirements in the jurisdictions we operate. We ensure full transparency and compliance in all our operations.',
  },
];