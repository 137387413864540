import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../../../lib/utils';
import { Icons } from '../../../assets/icons';
import { Avatar, AvatarFallback, AvatarImage } from '../../ui/avatar';
import { routes } from '../../../utils/route';

const Sidebar = ({ user }: { user: any }) => {
  let profilePicture =
    user && user.profilePicture
      ? user.profilePicture
      : '/assets/images/profileImagePlaceholder.png';
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const firstName = user?.firstname;
  const lastName = user?.lastname;
  const fullName =
    firstName && lastName ? `${firstName} ${lastName}` : firstName || lastName;

  const sidebarItems = [
    {
      path: routes.dashboard,
      icon: Icons.HomeIcon,
      label: 'Home',
      type: 'button',
    },
    {
      path: routes.market,
      icon: Icons.MarketIcon,
      label: 'Market',
    },
    {
      path: routes.profile,
      icon: Icons.ProfileIcon,
      label: 'Profile',
      type: 'button',
    },
    {
      path: routes.myAsset,
      icon: Icons.HouseIcon,
      label: 'My assets',
      type: 'button',
    },
  ];

  if (user?.accountType.toLowerCase() === 'corporate') {
    sidebarItems.push({
      path: routes.addAsset,
      icon: Icons.AssetIcon,
      label: 'Upload for Sale',
      type: 'button',
    });
  }

  const isActiveRoute = (path: string) => {
    if (pathname.startsWith('/asset/')) {
      // Check the state to determine which link should be active
      return location.state?.from === path;
    }
    return pathname.startsWith(path);
  };

  const handleNavigation = (path: string) => {
    // When navigating to Market or My assets, reset the state
    navigate(path, { state: { from: path } });
  };

  return (
    <div className="hidden lg:flex w-[280px] dark:bg-transparent bg-white flex-col items-start gap-10 p-4">
      <div className="flex items-center gap-6 cursor-pointer">
        <Link to="/" className="w-10">
          <img className="w-full h-16" src="/LOGO.png" alt="Logo" />
        </Link>
        <div className="flex items-center gap-2 mt-2">
          <Avatar>
            <AvatarImage src={profilePicture} className="object-cover border" />
            <AvatarFallback>
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName?.charAt(0)
                : fullName
                    ?.split(' ')
                    .map((name: string) => name.charAt(0))
                    .join('')}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="text-xs text-light-grey">Hello</p>
            <h3 className="text-sm font-semibold truncate w-36">
              {user?.accountType.toLowerCase() === 'corporate'
                ? user?.companyName
                : firstName}
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-center justify-start w-full gap-2">
        {sidebarItems.map(({ path, icon: Icon, label }, index) => {
          const isActive = isActiveRoute(path);
          const classNames = cn(
            'flex items-center flex-col border dark:border-[#3b3a3a] rounded-xl p-2 gap-1 cursor-pointer w-full',
            {
              'border-primary dark:border-primary border': isActive,
            }
          );

          const iconClassNames = cn('fill-[#BEBEBE] w-4 h-4 text-[#BEBEBE]', {
            'text-primary fill-primary': isActive,
          });

          const textClassNames = cn('text-[13px] text-[#BEBEBE]', {
            'text-primary font-semibold': isActive,
          });

          return (
            <button
              key={index}
              type="button"
              className={classNames}
              onClick={() => handleNavigation(path)}
            >
              <Icon className={iconClassNames} />
              <span className={textClassNames}>{label}</span>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Sidebar;
