import React, { useContext, useState } from 'react';
import { CurrencyFormat } from '../../utils/helper';
import { FC } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Like from '../dashboard/Like';
import { Icons } from '../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { Button } from './button';
import { GlobalContext, GlobalContextType } from '../../context/GlobalContext';
import { AspectRatio } from './aspect-ratio';
import { routes } from '../../utils/route';

interface Props {
  asset: any;
  showLike?: boolean;
  similar?: string;
  similarId?: string;
  isPurchased?: boolean;
}

const AssetCard: FC<Props> = ({
  asset,
  showLike = true,
  similar,
  similarId,
  isPurchased,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { users } = useContext(GlobalContext) as GlobalContextType;
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const pathname = location.pathname;
  const myAsset = users?.id === asset?.userId || false;
  const assetLocation =
    asset?.lga && asset?.state
      ? `${asset.lga}, ${asset.state}`
      : asset?.lga || asset?.state || '';

  function getCategoryAndNameFromRoute(): {
    categoryValue?: string;
    name?: string;
  } {
    switch (pathname) {
      case routes.market:
        return { categoryValue: '6', name: 'Market' };
      case routes.dashboard:
        if (similar) {
          return { categoryValue: similarId, name: 'Home' };
        } else {
          console.warn('Unexpected similar for Home:', similar);
          return { categoryValue: '', name: 'Home' };
        }
      case routes.myAsset:
        // Handle cases based on similar for "my-asset" route
        if (similar === 'UpForSale') {
          return { categoryValue: '0', name: 'My Assets' };
        } else if (similar === 'Sold') {
          return { categoryValue: '1', name: 'My Assets' };
        } else if (similar === 'Purchased') {
          return { categoryValue: '2', name: 'My Assets' };
        } else {
          console.warn('Unexpected similar for My-Asset:', similar);
          return { categoryValue: '', name: 'My Assets' }; // Use default
        }
      default:
        return {}; // Return empty object for unknown routes
    }
  }

  return (
    <div
      style={{ boxShadow: '0px 10px 30px 0px rgba(0, 0, 0, 0.04)' }}
      onClick={() => {
        !isPurchased &&
          navigate(`${routes.asset}/${asset?.id}`, {
            state: {
              route: getCategoryAndNameFromRoute(),
              from: pathname ?? '',
            },
          });
      }}
      className="dark:bg-[#252836] bg-white flex-shrink-0 max-w-[250px] gap-2 sm:gap-3 rounded-2xl p-2 sm:p-4 dark:border-[#3b3a3a] sm:border w-full flex flex-col items-center justify-center"
    >
      <div className="w-full h-full">
        <AspectRatio ratio={16 / 12}>
          {!imageLoaded && (
            <div className="w-full h-full bg-gray-200 rounded-xl animate-pulse" />
          )}
          <img
            src={
              asset?.defaultImage ?? '/assets/images/default-fallback-image.png'
            }
            alt={'asset' + (asset?.id + 1)}
            style={{
              objectFit: 'cover',
              width: '100%',
              height: '100%',
              borderRadius: '12px',
              display: imageLoaded ? 'block' : 'none',
            }}
            loading="lazy"
            onLoad={() => setImageLoaded(true)}
          />
        </AspectRatio>
      </div>
      <div className="flex items-start w-full gap-2">
        <button type="button" className="bg-[#4489C60F] p-3 rounded-lg">
          <Icons.AssetHome />
        </button>

        <div className="flex flex-col w-full cursor-pointer">
          <p className="text-light-grey font-normal text-[10px]">
            <span className="text-[#4D5D6A] dark:text-white font-bold text-sm">
              {asset?.availableUnits} Units
            </span>{' '}
            of {asset?.units}
          </p>{' '}
          <p className="flex items-center text-light-grey text-[11px] gap-1 flex-shrink-0 wrap-asset-location w-full">
            {assetLocation}
          </p>
        </div>
      </div>{' '}
      <div className="w-full space-y-3">
        <hr className="bg-[#848a8f]" />
        {myAsset && (
          <Button className="text-[#00CB6A] bg-[#E1FFED] hover:bg-[#E1FFED]/80 shadow-none rounded-xl text-[13px] p-2 w-full">
            {Number(asset?.units) - Number(asset?.availableUnits)} Units sold
            out
          </Button>
        )}
        {isPurchased ? (
          <Link
            className="flex items-center justify-between gap-3"
            to={`${routes.asset}/${asset?.id}`}
          >
            <span className="text-xs font-medium text-primary text-[13px]">
              View Details{' '}
              <FontAwesomeIcon icon={faArrowRight} className="ml-3" />
            </span>
          </Link>
        ) : (
          <div className="flex items-center justify-between gap-3">
            <p className="font-normal text-light-grey">
              <span className="text-[#4D5D6A] dark:text-white font-semibold text-sm">
                <CurrencyFormat value={asset?.unitPrice || 0} currency="NGN" />
              </span>
              <span className="ml-1 text-xs">Unit</span>{' '}
            </p>
            {showLike && (
              <Like
                isLiked={asset?.isFavoriteAsset}
                disabled={false}
                assetId={asset?.id}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AssetCard;
