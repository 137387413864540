import MyAsset from '../../components/dashboard/MyAsset';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';
import { useGetMyAsset } from '../../helpers/api/useAsset';

const MyAssetPage = () => {
  const { isLoading: dealsLoading, isError, data, mutate } = useGetMyAsset();
  const myAssets = data?.data?.data;

  if (isError) {
    return (
      <ErrorMessage
        message={'Error getting asset'}
        refetch={() => {
          document.location.reload();
        }}
      />
    );
  }

  return (
    <MyAsset
      firstAsset={myAssets && myAssets?.slice(0, 1)[0]}
      assets={myAssets ?? []}
      isLoading={dealsLoading}
      setTab={mutate}
    />
  );
};

export default MyAssetPage;
