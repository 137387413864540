import { TOKEN } from '../../utils/token';
import { IErrorResponseType } from '../../interface/common/error';
import {
  QueryClient,
  keepPreviousData,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { useUrls } from '../useUrls';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import useAxiosWithInterceptor from '../axiosConfig';

export const errorConnection =
  'Error connecting to server, please check your internet connection';

export const useLogin = () => {
  const setCookie = useCookies()[1];
  const [show, setShow] = useState<Boolean>(false);
  const { loginUrl } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(loginUrl, payload);
    },

    onSuccess: (res: any) => {
      const loginDetails = res?.data?.data;
      // const setCookie = (name: string, value: string) => {
      //   document.cookie = `${name}=${value || ''}; path=/`;
      // };

      if (res?.data?.responseMessage.toLowerCase() === 'otp sent.') {
        toast.success('Success', {
          description: 'Successful! Please enter your OTP.',
        });
        setShow(true);
      }
      setCookie(TOKEN.ACCESS, loginDetails.jwtToken);
      setCookie(TOKEN.TOKENEXP, loginDetails.jwtTokenExpiry);
      setCookie(TOKEN.ID, loginDetails.userId);
      setCookie(TOKEN.EMAIL, loginDetails?.email);
    },
  });
  const typedError = error as unknown as IErrorResponseType;
  const errorObject = typedError?.response?.data?.errors;
  const errorKeys = errorObject && Object.keys(errorObject)[0];

  // Get the first error message
  const firstError =
    errorKeys && typeof errorObject === 'object' && errorObject !== null
      ? errorObject?.[errorKeys][0]
      : null;

  // If errors exist, show the first one
  const errorMessage = typedError?.response?.data?.errors
    ? firstError
    : typedError?.response?.data?.responseMessage || errorConnection;

  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError: show ? false : isError,
    errorMessage,
    data: data?.data,
    show,
    setShow,
  };
};

export const useVerifyUser = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(
        `${user}/validate-account-confirmation`,
        payload
      );
    },
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorObject = typedError?.response?.data?.errors;
  const errorKeys = errorObject && Object.keys(errorObject)[0];
  // Get the first error message
  const firstError =
    errorKeys && typeof errorObject === 'object' && errorObject !== null
      ? errorObject?.[errorKeys][0]
      : null;

  // If errors exist, show the first one
  const errorString = typedError?.response?.data?.errors
    ? firstError
    : typedError?.response?.data?.responseMessage || errorConnection;

  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError,
    error,
    errorMessage: errorString,
    data: data?.data,
  };
};

export const useGetOtp = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutateAsync, isPending, isSuccess, isError, error, data } =
    useMutation({
      mutationFn: (payload: any) => {
        return axiosInstance.post(
          `${user}/initiate-account-confirmation`,
          payload
        );
      },
    });
  const typedError = error as unknown as IErrorResponseType;
  const errorObject = typedError?.response?.data?.errors;
  const errorKeys = errorObject && Object.keys(errorObject)[0];

  // Get the first error message
  const firstError =
    errorKeys && typeof errorObject === 'object' && errorObject !== null
      ? errorObject?.[errorKeys][0]
      : null;

  // If errors exist, show the first one
  const errorString = typedError?.response?.data?.errors
    ? firstError
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    mutateAsync,
    isLoading: isPending,
    isSuccess,
    isError,
    error: errorString,
    data,
  };
};

export const useLogout = () => {
  const router = useNavigate();
  const removeCookie = useCookies()[2];
  const queryClient = new QueryClient();

  const clearUserData = () => {
    removeCookie(TOKEN.ACCESS);
    removeCookie(TOKEN.ID);
    removeCookie(TOKEN.TOKENEXP);
    removeCookie(TOKEN.EMAIL);
    sessionStorage.clear();
    localStorage.removeItem('createAssetData');
  };

  const logoutUser = async () => {
    clearUserData();
    router('/login');
    queryClient.removeQueries();
    queryClient.clear();
    queryClient.resetQueries();
  };

  return { logoutUser };
};

export const useVerifyAccount = () => {
  const setCookie = useCookies()[1];
  const { otpUrl } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const { mutate, isPending, isSuccess, isError, error, data } = useMutation({
    mutationFn: (payload: any) => {
      return axiosInstance.post(otpUrl, payload);
    },
    onSuccess: (res: any) => {
      const loginDetails = res?.data?.data;
      setCookie(TOKEN.TOKENEXP, loginDetails.jwtTokenExpiry);
      setCookie(TOKEN.ACCESS, loginDetails.jwtToken);
      setCookie(TOKEN.ID, loginDetails.userId);
    },
  });
  const typedError = error as unknown as IErrorResponseType;
  const errorObject = typedError?.response?.data?.errors;
  const errorKeys = errorObject && Object.keys(errorObject)[0];

  // Get the first error message
  const firstError =
    errorKeys && typeof errorObject === 'object' && errorObject !== null
      ? errorObject?.[errorKeys][0]
      : null;

  // If errors exist, show the first one
  const errorString = typedError?.response?.data?.errors
    ? firstError
    : typedError?.response?.data?.responseMessage || errorConnection;

  return {
    mutate,
    isLoading: isPending,
    isSuccess,
    isError,
    error: errorString,
    data: data?.data?.data,
  };
};

export const useGetTimeZones = () => {
  const { user } = useUrls();
  const axiosInstance = useAxiosWithInterceptor();
  const {
    isPending,
    isError,
    error,
    data,
    refetch,
    isPlaceholderData,
    isFetching,
  } = useQuery({
    queryKey: ['get-timezones'],
    queryFn: async () => {
      const response = await axiosInstance.get(`${user}/timezone`);
      return response.data;
    },
    refetchOnWindowFocus: false,
    placeholderData: keepPreviousData,
  });

  const typedError = error as unknown as IErrorResponseType;
  const errorString = Array.isArray(typedError?.response?.data?.responseMessage)
    ? typedError?.response?.data?.responseMessage[0]
    : typedError?.response?.data?.responseMessage || errorConnection;
  return {
    isLoading: isPending || isFetching,
    isError,
    data,
    refetch,
    isPlaceholderData,
    errorMessage: errorString,
  };
};
