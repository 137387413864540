import './checkout.css';
import { useEffect, useState } from 'react';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useLocation, useNavigate } from 'react-router-dom';
import { CurrencyFormat } from '../../utils/helper';
import { Button } from '../ui/button';
import { toast } from 'sonner';
import React from 'react';
import { Icons } from '../../assets/icons';
import ContainerLayout from '../core/containerLayout';
import { routes } from '../../utils/route';

interface IAssetDetails {
  id: number;
  title: string;
  defaultImage: string | null;
  propertyTypeId: number;
  expectedRoi: number;
  isInvestmentConvertible: boolean;
  propertyTypeMoratoriumId: number;
  unitPrice: number;
  purchasedUnit: number;
  units: number;
  availableUnits: number;
  state: string | null;
  lga: string | null;
  fullAddress: string | null;
  userId: number;
  description: string;
  status: string;
  propertyType: any; // Type definition for propertyType is missing in the provided object
  moratorium: any; // Type definition for moratorium is missing in the provided object
  dateCreated: string;
  isFavoriteAsset: boolean;
  isDeleted: boolean;
  dateDeleted: string | null;
}

interface CheckoutDetails {
  orderId: number;
  assetDetails: IAssetDetails | null;
  grandTotalAmount: number;
  commission: number;
  subTotal: number;
  units: number;
  unitPrice: number;
}

const Checkout = () => {
  const axiosPrivate = useAxiosPrivate();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [fallbackImage, setFallbackImage] = useState<string>('');
  const initialCheckoutDetails: CheckoutDetails = {
    orderId: 0, // Initialize with appropriate values
    assetDetails: null,
    grandTotalAmount: 0,
    commission: 0,
    subTotal: 0,
    units: 0,
    unitPrice: 0,
  };
  const [checkoutDetails, setCheckoutDetails] = useState<CheckoutDetails>(
    initialCheckoutDetails
  );
  const { assetDetails } = checkoutDetails as CheckoutDetails;

  useEffect(() => {
    if (location.state && location.state.details) {
      setCheckoutDetails(() => location.state.details);
      setFallbackImage(location.state.fallbackImg);
    } else {
      navigate(-1);
    }
  }, [location.state, navigate]);

  const handleCheckout = async (e: any) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true when starting checkout process
    try {
      let paymentDetails = await axiosPrivate.get(
        `asset/asset-payment-processors/${checkoutDetails?.orderId}`
      );
      navigate(`/payment`, {
        state: {
          details: paymentDetails.data.data,
          orderId: checkoutDetails?.orderId,
          assetDetails: assetDetails,
        },
      });
    } catch (err: any) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    } finally {
      setIsLoading(false); // Reset loading state when checkout process completes (whether successful or not)
    }
  };

  return (
    <ContainerLayout>
      <Button
        variant="ghost"
        className="bg-transparent cursor-pointer "
        onClick={() => {
          navigate(`/${routes.asset}/${checkoutDetails?.assetDetails?.id}`, {
            state: { OrderId: checkoutDetails?.orderId },
          });
        }}
      >
        <FontAwesomeIcon icon={faAngleLeft} />
      </Button>
      <div className="w-full max-w-2xl gap-4 mx-auto">
        <div className="flex flex-col w-full h-auto gap-4">
          <img
            className="w-full h-[400px] rounded-2xl sm:rounded-3xl object-cover"
            src={(assetDetails && assetDetails?.defaultImage) ?? fallbackImage}
            alt=""
          />
          <div className="grid grid-cols-2 text-black">
            <p className="text-lg font-medium capitalize md:text-2xl">
              {assetDetails && assetDetails?.title}
            </p>
            <p className="font-normal place-self-end">
              <span className="text-base font-bold md:text-2xl ">
                <CurrencyFormat
                  value={assetDetails?.unitPrice || 0}
                  currency="NGN"
                />
              </span>
              /<span className="text-[12px]">Unit</span>{' '}
            </p>
          </div>
          <div className="grid grid-cols-2">
            <div className="gap-2">
              <p className="text-black">
                <span className="text-base font-medium md:text-2xl">
                  {assetDetails && assetDetails?.availableUnits} Units
                </span>{' '}
                of {assetDetails && assetDetails?.units}
              </p>
            </div>
            <div className="place-self-end">
              <p className="text-base text-white md:text-xl">
                {(assetDetails?.lga && assetDetails?.lga + ',') || ''}{' '}
                {(assetDetails && assetDetails?.state) || ''}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" max-w-2xl gap-4 mx-auto w-full text-light-grey bg-[#F4F4F4]  dark:bg-transparent dark:border-[#3b3a3a] dark:border rounded-xl space-y-4 pt-5">
        <div className="grid grid-cols-2 px-4">
          <h5 className="text-xl font-semibold text-[#4D5D6A]">
            Payment Details
          </h5>
        </div>
        <div className="grid grid-cols-2 px-4">
          <p>Asset Price</p>
          <div className="place-self-end">
            <CurrencyFormat
              value={(assetDetails && assetDetails.unitPrice) || 0}
              currency="NGN"
            />
            /Unit
          </div>
        </div>
        <div className="grid grid-cols-2 px-4">
          <p>Units</p>
          <p className="place-self-end">{checkoutDetails.units} Units</p>
        </div>
        <div className="grid grid-cols-2 px-4">
          <p>Commission</p>
          <div className="place-self-end">
            <CurrencyFormat
              value={checkoutDetails?.commission || 0}
              currency="NGN"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 px-4">
          <p>Sub Total</p>
          <div className="place-self-end">
            <CurrencyFormat
              value={checkoutDetails?.subTotal || 0}
              currency="NGN"
            />
          </div>
        </div>
        <div className=" px-4 grid grid-cols-2 py-4 border-t border-dashed border-[#CECECE]">
          <h5>Total Charges</h5>
          <div className="place-self-end">
            <CurrencyFormat
              value={
                (checkoutDetails && Number(checkoutDetails.grandTotalAmount)) ??
                0
              }
              currency="NGN"
            />
          </div>
        </div>
      </div>
      <div className="sm:max-w-[335px] w-full mx-auto flex items-center gap-4 justify-center mt-4">
        <Button
          variant="secondary"
          onClick={() => {
            navigate(`${routes.asset}/${checkoutDetails?.assetDetails?.id}`, {
              state: { OrderId: checkoutDetails?.orderId },
            });
          }}
          className="w-full p-6 sm:w-72 rounded-xl"
        >
          <FontAwesomeIcon icon={faAngleLeft} className="mr-2" /> Go back
        </Button>
        <Button
          onClick={(e) => handleCheckout(e)}
          className="w-full p-6 sm:w-72 rounded-xl"
          disabled={isLoading}
        >
          {isLoading && <Icons.Spinner className="w-5 h-5 mr-2 animate-spin" />}
          Proceed to Checkout{' '}
          <FontAwesomeIcon icon={faAngleLeft} className="ml-2 rotate-180" />
        </Button>
      </div>
    </ContainerLayout>
  );
};

export default Checkout;
