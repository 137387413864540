import { ArrowLeftIcon } from '@radix-ui/react-icons';
import React, { useEffect, useRef, useState } from 'react';
import { cn } from '../../lib/utils';

type Props = {
  hideArrow?: boolean;
  children: React.ReactNode;
  className?: string;
  height?: number;
  isJustify?: boolean;
};

const Carousel = (props: Props) => {
  const { height = 262, isJustify = false } = props;
  const [showNavButton, setShowNavButton] = useState({
    left: false,
    right: true,
  });
  const sliderRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false); // New state for dragging
  const [dragStartX, setDragStartX] = useState<number>(0);
  const [scrollLeft, setScrollLeft] = useState<number>(0);
  const [hasOverflow, setHasOverflow] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      if (!sliderRef.current) return;

      const isAtEnd =
        sliderRef.current.scrollLeft + sliderRef.current.offsetWidth >=
        sliderRef.current.scrollWidth;
      const isAtStart = sliderRef.current.scrollLeft === 0;

      setShowNavButton({ left: !isAtStart, right: !isAtEnd });
    };

    if (sliderRef.current) {
      sliderRef.current.addEventListener('scroll', onScroll);
    }

    return () => {
      if (sliderRef.current) {
        sliderRef.current.removeEventListener('scroll', onScroll);
      }
    };
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    const event = e as unknown as MouseEvent;
    setIsDragging(true);
    sliderRef.current?.classList.add('active');
    setDragStartX(event.clientX - (sliderRef.current?.offsetLeft || 0));
    setScrollLeft(sliderRef.current?.scrollLeft || 0);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    sliderRef.current?.classList.remove('active');
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDragging) return;
    e.preventDefault();

    const x = e.clientX - (sliderRef.current?.offsetLeft || 0);
    const deltaX = x - dragStartX;

    // If the drag threshold is reached, set hasDragged to true
    if (!isDragging && Math.abs(deltaX) >= 4) {
      setIsDragging(true);
    }

    const walk = deltaX * 3; // Adjust scroll-fast as needed
    if (sliderRef.current) {
      sliderRef.current.scrollLeft = scrollLeft - walk;
    }
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isDragging, dragStartX, scrollLeft]);

  const scrollToLeft = () => {
    if (sliderRef.current) {
      const { scrollLeft } = sliderRef.current;
      const newScrollLeft = Math.max(
        scrollLeft - sliderRef.current.offsetWidth,
        0
      );

      sliderRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, offsetWidth } = sliderRef.current;
      const maxScrollLeft = scrollWidth - offsetWidth;
      const newScrollLeft = Math.min(
        scrollLeft + sliderRef.current.offsetWidth,
        maxScrollLeft
      );

      sliderRef.current.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  // Inside your Carousel component

  const checkForOverflow = () => {
    if (sliderRef.current) {
      const hasOverflow =
        sliderRef.current.scrollWidth > sliderRef.current.clientWidth;

      // Use the `hasOverflow` value as needed
      if (hasOverflow) {
        // Do something when there is overflow
        setHasOverflow(true);
      } else {
        // Do something when there is no overflow
        setHasOverflow(false);
      }
    }
  };

  // Run `checkForOverflow` whenever the screen width changes or the carousel rerenders
  useEffect(() => {
    checkForOverflow();

    // Add window resize event listener
    const handleResize = () => {
      checkForOverflow();
    };

    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [sliderRef.current, props.children]); // Add dependencies here as needed
  // ...

  return (
    <div className={`relative item-center ${props.className} `}>
      {!props.hideArrow && hasOverflow && (
        <NavButton
          direction="left"
          onClick={scrollToLeft}
          visible={showNavButton}
        />
      )}

      <div
        className={`flex ${
          isJustify ? 'justify-between' : 'justify-start'
        } overflow-x-auto [&::-webkit-scrollbar]:hidden gap-4 relative ${
          isDragging ? 'no-pointer-events' : ''
        }`}
        ref={sliderRef}
        onMouseDown={handleMouseDown}
      >
        {props.children}
      </div>
      {hasOverflow && showNavButton.right && (
        <div
          className={`        
            absolute z-30 top-0 -right-3
         `}
        >
          <svg
            width="80"
            className="rounded-lg"
            height="0"
            viewBox="0 0 80 262"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="231"
              height={height}
              fill="url(#paint0_linear_5721_87503)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_5721_87503"
                x1="66.3646"
                y1="131"
                x2="14.0387"
                y2="131"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#FCFCFC" />
                <stop offset="1" stopColor="#FCFCFC" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      )}
      {!props.hideArrow && hasOverflow && (
        <>
          <NavButton
            direction="right"
            onClick={scrollToRight}
            visible={showNavButton}
          />
        </>
      )}
    </div>
  );
};

export default Carousel;

function NavButton({
  direction,
  onClick,
  visible,
}: {
  direction: 'left' | 'right';
  onClick: () => void;
  visible: { left: boolean; right: boolean };
}) {
  const isLeft = direction === 'left';

  return (
    <button
      className={cn(
        'absolute z-40 transition duration-100 rounded-full p-2 border bg-[#1D1641] dark:bg-gray-200 dark:border-black border-gray-300 top-1/2 transform -translate-y-1/2',
        direction === 'left' ? 'left-0' : 'right-0',
        {
          'cursor-not-allowed hidden':
            (isLeft && !visible.left) || (!isLeft && !visible.right),
          'block ': (isLeft && visible.left) || (!isLeft && visible.right),
        }
      )}
      onClick={onClick}
    >
      {isLeft ? (
        <ArrowLeftIcon className="text-white dark:text-black " />
      ) : (
        <ArrowLeftIcon className="text-white rotate-180 dark:text-black" />
      )}
    </button>
  );
}
