/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'sonner';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils';
import { Icons } from '../../assets/icons';
import { useLogin, useVerifyAccount } from '../../helpers/api/useAuth';
import Constants from '../../utils/constants';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '../ui/dialog';
import { Input } from '../ui/input';
import { Label } from '../ui/label';
import { Button } from '../ui/button';
import React from 'react';
import { routes } from '../../utils/route';

export const InputVariants = cva('relative', {
  variants: {
    iconPosition: {
      left: ' absolute left-3 top-1/2 -translate-y-1/2 transform text-muted-foreground',
      right:
        ' absolute left-auto right-2 top-1/2 -translate-y-1/2 transform text-muted-foreground',
    },
  },
  defaultVariants: {
    iconPosition: 'left',
  },
});

const Login = () => {
  const navigate = useNavigate();
  const [loginOTP, setLoginOTP] = useState<string>('');
  const [passwordShown, setPasswordShown] = useState(false);
  const [credentials, setCredentials] = useState({
    companyName: '',
    pushNotificationId: '3',
    username: '',
    password: '',
  });
  const {
    mutate,
    isLoading: isLoginLoading,
    isError: isLoginError,
    isSuccess: isLoginSuccess,
    errorMessage,
    data,
    show,
    setShow,
  } = useLogin();

  const {
    mutate: verifyAccount,
    isLoading: isVerifying,
    isSuccess: isVerified,
    isError: isVerifyError,
    error: verifyError,
    data: otpRes,
  } = useVerifyAccount();

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    mutate(credentials);
  };

  const redirect = useCallback(
    (data: any) => {
      switch (data.accountStatus) {
        case Constants.pendingEmailAndPhoneConfirmation:
          navigate('../register', {
            state: {
              section: 'verification',
              subsection: data.isPhoneConfirmed ? 'email' : 'phone',
            },
          });
          break;
        case Constants.pendingIdentityVerification:
        case Constants.identityVerificationDeclined:
          navigate(routes.register, {
            state: {
              section: 'requiredSteps',
            },
          });
          break;
        case Constants.completeCorporateOnboarding:
          navigate(routes.register, {
            state: {
              section: 'register',
              subsection: 'contactInfo',
            },
          });
          break;
        case Constants.firstTimeLogin:
          navigate(routes.register, {
            state: {
              section: 'changePassword',
            },
          });
          break;
        case Constants.awaitingVerificationApproval:
          navigate(routes.dashboard);
          break;
        case Constants.active:
          navigate(routes.dashboard, { replace: true });
          break;
        default:
          navigate('/', { replace: true });
      }
    },
    [navigate]
  );

  useEffect(() => {
    if (isLoginSuccess) {
      redirect(data?.data);
    }
  }, [isLoginSuccess, data, navigate, redirect]);

  useEffect(() => {
    if (isLoginError) {
      toast.error('Login Error', {
        description: errorMessage || 'Error Logging in',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoginError]);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e: any) => {
    setCredentials((prev) => ({ ...prev, [e.target.id]: e.target.value }));
  };

  const handle2fa = (e: any) => {
    setLoginOTP(e.target.value);
  };

  useEffect(() => {
    if (isVerified) {
      redirect(otpRes);
      setShow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isVerified, otpRes, redirect]);

  useEffect(() => {
    if (isVerifyError) {
      toast.error('Verification Error', {
        description: verifyError,
      });
    }
  }, [isVerifyError, verifyError]);

  const ForgotPassword = () => {
    navigate('../register', {
      state: {
        section: 'forgotPassword',
      },
    });
  };

  return (
    <div className="flex flex-col items-center justify-center w-full gap-4">
      <div className="text-center">
        <h4 className="text-2xl font-semibold">Login To Your Account</h4>
        <p>Let's get you signed in</p>
      </div>
      <form onSubmit={onSubmit} className="flex flex-col w-full gap-3">
        <div className="w-full">
          <label
            htmlFor="email"
            className="text-[13px] text-[#A2A1A8] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Email
          </label>
          <input
            id="username"
            type="email"
            placeholder="Enter your email"
            className="dark:bg-transparent flex items-center justify-center outline-none text-black dark:text-white w-full rounded-lg border dark:border-white/20 border-[#00000029] bg-transparent p-3 text-base sm:text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
            onChange={(e) => handleChange(e)}
          />
        </div>
        <div className="relative w-full">
          <label
            htmlFor="password"
            className="text-[13px] text-[#A2A1A8] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Password
          </label>
          <input
            id="password"
            placeholder="********"
            onChange={(e) => handleChange(e)}
            type={passwordShown ? 'text' : 'password'}
            className="dark:bg-transparent flex items-center justify-center outline-none text-black dark:text-white w-full rounded-lg border dark:border-white/20 border-[#00000029] bg-transparent p-3 text-base sm:text-sm placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
          />
          <span
            className={cn(
              'mt-[11px]',
              InputVariants({ iconPosition: 'right' })
            )}
            onClick={togglePassword}
          >
            {!passwordShown ? (
              <FontAwesomeIcon className="passwordIcon" icon={faEye} />
            ) : (
              <FontAwesomeIcon className="passwordIcon" icon={faEyeSlash} />
            )}
          </span>
        </div>
        <div className="flex flex-col w-full gap-2 text-center">
          <button
            type="submit"
            disabled={
              isLoginLoading ||
              credentials.username === '' ||
              credentials.password === ''
            }
            className="bg-primary inline-flex items-center justify-center w-full p-3 text-sm font-medium text-white transition-colors rounded-lg whitespace-nowrap focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 hover:bg-[#8C93B4]"
          >
            {!isLoginLoading ? (
              'Sign in'
            ) : (
              <Icons.Spinner className="w-6 h-6 bg-transparent animate-spin" />
            )}
          </button>
          <p
            className="text-sm text-center text-blue-600 cursor-pointer"
            onClick={ForgotPassword}
          >
            Forgot Password
          </p>
          <p>
            Don't have an account?{' '}
            <Link to="/register" className="text-sm text-blue-600">
              Sign Up
            </Link>
          </p>
        </div>
      </form>

      {/* --------Modal---------- */}
      <Dialog
        open={show as boolean}
        onOpenChange={() => {
          setShow(false);
        }}
      >
        <DialogContent className="sm:max-w-md">
          <DialogHeader>
            <DialogTitle>Please verify your account</DialogTitle>
          </DialogHeader>
          <form
            className="space-y-3"
            onSubmit={(e) => {
              e.preventDefault();
              verifyAccount({ email: credentials.username, otp: loginOTP });
            }}
          >
            <Label htmlFor="otp">Enter your OTP</Label>
            <Input type="text" onChange={(e) => handle2fa(e)} />
            <DialogFooter className="flex items-center justify-between w-full gap-3 mt-auto">
              <Button
                className="p-3.5 h-12 text-sm rounded-lg w-full"
                disabled={false}
                variant="secondary"
                type="button"
                onClick={() => setShow(false)}
              >
                Close
              </Button>
              <Button
                disabled={(isVerifying as boolean) || loginOTP.length <= 5}
                className={cn(
                  'rounded-lg text-sm w-full font-medium disabled:opacity-50 outline-none p-3.5 h-12 flex items-center justify-center'
                )}
                type="submit"
              >
                {isVerifying ? (
                  <Icons.Spinner className="w-6 h-6 animate-spin" />
                ) : (
                  'Submit'
                )}
              </Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Login;

