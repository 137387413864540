import { useContext, useEffect, useState } from 'react';
import { OnboardContext } from '../../../context/OnboardContext';
import { toast } from 'sonner';
import { Button } from '../../ui/button';

const ProfilePicture = ({ next, getBase64 }) => {
  const { fileToBeUploaded, dispatch, clearDocument } =
    useContext(OnboardContext);
  const [file, setFile] = useState();
  const [uploadedFile, setUploadedFile] = useState({
    documentId: null,
    document: null,
  });
  const placeholder = './assets/images/profilePlaceHolder.webp';

  const handleClick = () => {
    // Trigger the file input when the button is clicked
    document.getElementById('fileInput').click();
  };

  useEffect(() => {
    if (clearDocument) {
      setFile('');
      setUploadedFile(null);
    }
  }, [clearDocument]);

  const upload = async (e) => {
    // Get the file from the event
    const selectedFile = e.target.files[0];
    setFile(URL.createObjectURL(selectedFile));
    try {
      getBase64(selectedFile, async (base64) => {
        let payload = {
          documentId: fileToBeUploaded?.id || null,
          document: base64,
        };
        setUploadedFile(() => payload);
      });
      // Reset the value of the file input
      e.target.value = null;
    } catch (err) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
  };

  const handleUpload = async () => {
    if (!uploadedFile.document) return toast.warning('Select a picture');
    dispatch({ type: 'UPDATE_DOC', payload: uploadedFile });
    dispatch({ type: 'DOCS_UPLOADED', payload: fileToBeUploaded });
    next('requiredSteps');
  };

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-[459px] gap-6 mx-auto">
      <div className="flex flex-col w-full gap-3 text-center">
        <h4 className="text-2xl font-semibold text-[#0B2253]">
          Take your profile photo
        </h4>
        <p className="text-sm text-[#061E38]">
          Your profile photo helps people recognize you. Please note that once
          you submit your profile photo for review it cannot be changed.
        </p>
      </div>
      <div className="flex flex-col items-center justify-center text-sm sm:text-base max-w-[365px] mx-auto w-full gap-6">
        <ol className="list-decimal text-[#4D5D6A] px-4">
          <li>
            Face the camera directly with your eyes and mouth clearly visible.
          </li>
          <li>Make sure the photo is well lit, free of glare, and in focus.</li>
          <li>No photos of a photo, filters, or alterations.</li>
        </ol>
        <div className="w-[321px] h-[321px] rounded-[38.1px]">
          <img
            src={file || placeholder}
            alt="profile"
            className="w-full h-full rounded-[38.1px] object-cover"
          />
        </div>
        {/* Hidden file input */}
        <input
          hidden
          id="fileInput"
          accept=".png, .jpeg, .jpg"
          type="file"
          name=""
          onChange={(e) => upload(e)}
        />
      </div>
      <div className="flex flex-col items-center justify-center w-full max-w-xs gap-5 mx-auto">
        <Button
          variant="secondary"
          onClick={handleClick}
          className="w-full rounded-lg"
        >
          {uploadedFile?.document ? 'Retake' : 'Select a Picture'}
        </Button>
        <Button className="w-full" onClick={handleUpload}>
          Upload
        </Button>
      </div>
    </div>
  );
};

export default ProfilePicture;
