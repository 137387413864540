import { useEffect, useState, useCallback } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import ErrorMessage from '../../components/ui/error-fallback';
import React from 'react';
import { OrderHistory } from '../../components/dashboard/OrderHistory';
import { Button } from '../../components/ui/button';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import ContainerLayout from '../../components/core/containerLayout';

const OrderHistoryPage = () => {
  const axiosPrivate = useAxiosPrivate();
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [, setErrorMessage] = useState<string>('');

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const getHistory = useCallback(async () => {
    setLoading(true);
    try {
      let history = await axiosPrivate.get(`asset/order-history`);
      setHistory(history.data.data);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      setIsError(true);
      setErrorMessage(
        err.response?.data?.responseMessage || 'An error occurred'
      );
    }
  }, [axiosPrivate]);

  const refresh = () => {
    getHistory();
  };

  useEffect(() => {
    getHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isError) {
    return (
      <ErrorMessage
        message={'Error getting order history'}
        refetch={() => {
          // document.location.reload();
          refresh();
        }}
      />
    );
  }

  return (
    <ContainerLayout className="gap-2">
      <div className="px-4 py-0 md:px-0">
        <Button variant="ghost" className="p-0" onClick={goBack}>
          <FontAwesomeIcon icon={faAngleLeft} />{' '}
          <h4 className="font-medium text-[#4D5D6A] cursor-pointer">
            {'Back'}
          </h4>
        </Button>
      </div>
      <h1 className="px-4 text-lg font-medium sm:text-2xl md:px-0">
        Order History
      </h1>
      <OrderHistory history={history} loading={loading} />
    </ContainerLayout>
  );
};

export default OrderHistoryPage;
