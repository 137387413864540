import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../ui/form';
import { FieldValues, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '../../ui/button';
import { Input } from '../../ui/input';
import { ensureNumber, getBase64 } from '../../../utils/helper';
import { toast } from 'sonner';
import { Icons } from '../../../assets/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { Label } from '../../ui/label';
import {
  useCalculateAssetUnit,
  useEditAsset,
} from '../../../helpers/api/useAsset';
import { cn } from '../../../lib/utils';
import { Checkbox } from '../../ui/checkbox';
import { Calendar } from '../../ui/calendar';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';
import { Popover, PopoverContent, PopoverTrigger } from '../../ui/popover';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import {
  EditAssetFormSchema,
  EditAssetType,
  IEditAsset,
  IEditDocument,
  IEditImage,
} from './editSchema';
import { ExtendedEditAsset } from '.';
import { routes } from '../../../utils/route';

type Props = {
  setStep: (arg0: number) => void;
  data: ExtendedEditAsset | undefined;
  openPreview: () => void;
  onFinish: (data: any) => void;
  onCreateAssetAdd: (data: any) => void;
  isOpen: boolean;
  setTriggerDefaultValue: (arg0: boolean) => void;
};

const StepTwo: React.FC<Props> = ({
  setStep,
  data,
  onCreateAssetAdd,
  isOpen,
  setTriggerDefaultValue,
}) => {
  const params = useParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const assetForm = {
    title: '',
    description: '',
    moratoriumId: 0,
    referralCode: undefined,
    totalAssetValue: 0,
    availableUnits: 0,
    expectedROI: 0,
    expectedCompletion: '',
    unitPrice: 0,
    isInvestimentConvertable: false,
    documents: [],
    images: [],
    propertyTypeId: 0,
    address: {
      fullAddress: '',
      localGovernmentId: 0,
    },
  };
  const [showAllImages, setShowAllImages] = useState<boolean>(false);
  const [deletedImages, setDeletedImages] = useState<IEditImage[]>([]);
  const [deletedDocuments, setDeletedDocuments] = useState<IEditDocument[]>([]);
  const [images, setImages] = useState<IEditImage[]>([]);
  const [isPopulate, setIsPopulate] = useState<boolean>(true);
  const [documents, setDocuments] = useState<IEditDocument[]>([]);
  const [totalAssetValue, setTotalValue] = useState<number>();
  const [availableUnits, setAvailableUnits] = useState<number>();
  const imageInput = useRef<HTMLInputElement | null>(null);
  const fileInput = useRef<HTMLInputElement | null>(null);
  const form = useForm<EditAssetType>({
    resolver: yupResolver(EditAssetFormSchema),
    defaultValues: assetForm,
  });

  const {
    mutate,
    updateAssetSuccess,
    updateAssetError,
    isUpdatingAsset,
    errorMessage,
  } = useEditAsset(params?.id as string);

  useEffect(() => {
    // Update form values for images
    setValue('images', images as never, {
      shouldDirty: true,
      shouldTouch: true,
    });

    // Update form values for documents
    setValue('documents', documents as never, {
      shouldDirty: true,
      shouldTouch: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (updateAssetSuccess) {
      toast.success('Asset updated successfully');
      navigate(`${routes.asset}/${params?.id}`);
      localStorage.removeItem('createAssetData');
      queryClient.invalidateQueries({
        queryKey: ['asset-details', params?.id],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAssetSuccess]);

  useEffect(() => {
    if (updateAssetError) {
      toast.error(errorMessage || 'Failed updating asset');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateAssetError]);

  const {
    control,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (data && isPopulate) {
      setIsPopulate(true);

      const convertDocuments = data?.documents.map(
        (doc: any, index: number) => {
          return {
            name: `Document ${index + 1}`,
            file: doc.documentLink, // Add base64 representation to the image object
            removeDocument: false,
            assetDocumentId: doc?.id,
          };
        }
      );
      const convertImages = data?.images.map((img: any) => {
        return {
          isDefaultImage: img.isDefaultImage,
          file: img.documentLink, // Add base64 representation to the image object
          removeImage: false,
          imageId: img?.id,
        };
      });

      form.reset({
        assetId: data?.assetId,
        title: data.title,
        description: data.description,
        moratoriumId: ensureNumber(data.moratoriumId),
        propertyTypeId: ensureNumber(data.propertyTypeId),
        address: {
          fullAddress: data.address.fullAddress || '',
          localGovernmentId: ensureNumber(data.address.localGovernmentId.value),
          country: data?.address?.country?.value,
          state: data?.address?.state?.value,
        },
        totalAssetValue: data.totalAssetValue,
        availableUnits: ensureNumber(data.availableUnits),
        unitPrice: data.unitPrice,
        expectedCompletion: data.expectedCompletion ?? '',
        expectedROI: data.expectedROI,
        isInvestimentConvertable: data.isInvestimentConvertable ?? false,
      });
      setAvailableUnits(ensureNumber(data.availableUnits));
      setImages(convertImages ?? []);
      setDocuments(convertDocuments ?? []);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isPopulate]);

  useEffect(() => {
    onCreateAssetAdd({
      totalAssetValue: ensureNumber(form.getValues('totalAssetValue')),
      availableUnits: ensureNumber(form.getValues('availableUnits')),
      expectedROI: ensureNumber(form.getValues('expectedROI')),
      unitPrice: ensureNumber(form.getValues('unitPrice')),
      isInvestimentConvertable: form.getValues('isInvestimentConvertable'),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm: SubmitHandler<FieldValues> = async (values) => {
    const data = { ...values } as EditAssetType;

    const expectedCompletion = data?.expectedCompletion;
    const isoDateString = !expectedCompletion
      ? undefined
      : new Date(expectedCompletion).toISOString();

    // Filter images and documents to include only base64 files
    const base64Images =
      data.images?.filter((img: any) => img.file.startsWith('data:')) || [];
    const base64Documents =
      data.documents?.filter((doc: any) => doc.file.startsWith('data:')) || [];

    // Combine base64 files with deleted files
    const finalImages = [...base64Images, ...(deletedImages || [])];
    const finalDocuments = [...base64Documents, ...(deletedDocuments || [])];

    const payload: IEditAsset = {
      assetId: data?.assetId,
      title: data.title ?? '',
      description: data.description ?? '',
      moratoriumId: ensureNumber(data.moratoriumId),
      totalAssetValue: ensureNumber(data.totalAssetValue),
      availableUnits: ensureNumber(data.availableUnits),
      expectedROI: ensureNumber(data.expectedROI),
      expectedCompletion: isoDateString ?? '',
      unitPrice: ensureNumber(data.unitPrice),
      isInvestimentConvertable: data.isInvestimentConvertable ?? false,
      documents: finalDocuments,
      images: finalImages,
      propertyTypeId: ensureNumber(data.propertyTypeId),
      address: {
        fullAddress: data.address.fullAddress ?? '',
        localGovernmentId: ensureNumber(data.address.localGovernmentId),
      },
    };

    mutate(payload);
  };

  const stateId = form.getValues('address.state') ?? '';

  const {
    data: calData,
    isError: calError,
    isLoading: calLoading,
    isSuccess: calSuccess,
  } = useCalculateAssetUnit(
    availableUnits as number,
    totalAssetValue as number,
    String(data?.propertyTypeId),
    stateId
  );

  useEffect(() => {
    if (calSuccess) {
      setValue('totalAssetValue', calData?.totalAssetValue as never, {
        shouldValidate: true,
        shouldDirty: true,
      });
      setValue('unitPrice', calData?.unitPrice as never, {
        shouldValidate: true,
        shouldDirty: true,
      });

      setValue('availableUnits', ensureNumber(calData?.totalUnits) as never, {
        shouldValidate: true,
        shouldDirty: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calData, calSuccess]);

  const getAvailableUnits = form.getValues(
    'availableUnits'
  ) as IEditAsset['availableUnits'];

  const getTotalAssetValue = form.getValues(
    'totalAssetValue'
  ) as IEditAsset['totalAssetValue'];

  const getExpectedROI = form.getValues(
    'expectedROI'
  ) as IEditAsset['expectedROI'];

  const TotalAssetValue = useWatch({
    control,
    name: 'totalAssetValue',
  });

  const AvailableUnits = useWatch({
    control,
    name: 'availableUnits',
  });

  const ExpectedROI = useWatch({
    control,
    name: 'expectedROI',
  });

  useEffect(() => {
    const timeout = setTimeout(() => {
      setAvailableUnits(getAvailableUnits);
    }, 700);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [AvailableUnits]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTotalValue(getTotalAssetValue);
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TotalAssetValue]);

  const getImages =
    (useWatch({
      control,
      name: 'images',
    }) as IEditAsset['images']) ?? [];
  const getDocuments =
    (useWatch({
      control,
      name: 'documents',
    }) as IEditAsset['documents']) ?? [];

  // Define image upload function
  const MAX_FILE_SIZE_MB = 2; // Maximum file size allowed in MB

  const imageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || e.target.files.length === 0) return;

    const selectedFiles = Array.from(e.target.files) as File[];

    for (const file of selectedFiles) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        toast.error('Image size exceeds 2MB. Please choose a smaller image.');
        continue; // Skip this file and proceed with the next one
      }

      try {
        // Store the original file
        await new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = () => {
            const payload = {
              isDefaultImage: false,
              file: reader.result as string, // Assuming your payload expects a string
              removeImage: false,
              imageId: undefined,
            };

            // Update images state
            setImages((prev: any) => [...prev, payload]);
            resolve(null);
          };

          reader.onerror = () => {
            reject(reader.error);
          };

          reader.readAsDataURL(file);
        });

        // Clear any existing errors for the images field
        clearErrors('images');

        // Check if the maximum number of images is reached
        if (images.length >= 4) {
          // Clear any error message indicating maximum images requirement
          clearErrors('images');
        }
      } catch (err: any) {
        toast.error(err.response?.data?.responseMessage || 'An error occurred');
      }
    }

    // Reset the input field value to allow selecting the same file again
    e.target.value = '';
  };

  // Define file upload function
  const fileUpload = async (e: any) => {
    if (!e.target.value) return;

    const file = e.target.files[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
      toast.error(
        'Document size exceeds 2MB. Please choose a smaller document.'
      );
      return;
    }

    try {
      getBase64(file, async (base64: any) => {
        const payload = {
          file: base64,
          name: file.name,
          assetDocumentId: undefined,
          removeDocument: false,
        };

        // Check if the document is a duplicate
        const isDuplicate = documents?.some(
          (doc: any) => doc.name === payload.name
        );

        if (!isDuplicate) {
          // Update documents state
          setDocuments((prev) => [...prev, payload]);

          // Clear any existing errors for the documents field
          clearErrors('documents');

          // Check if the minimum number of documents is met
          if (documents.length >= 1) {
            // Clear any error message indicating minimum documents requirement
            clearErrors('documents');
          }
        } else {
          // Handle duplicate document
          toast.warning('This document already exists.');
        }
      });

      // Clear the input value
      e.value = null;
    } catch (err: any) {
      toast.error(err.response?.data?.responseMessage || 'An error occurred');
    }
    // Reset the input field value to allow selecting the same file again
    e.target.value = '';
  };

  // useEffect to update form values when images or documents state change
  useEffect(() => {
    // Update form values for images
    setValue('images', images as never, {
      shouldDirty: true,
      shouldTouch: true,
    });

    // Update form values for documents
    setValue('documents', documents as never, {
      shouldDirty: true,
      shouldTouch: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images, documents]);

  const removeImage = (index: number) => {
    const imageToRemove = getImages[index];
    let newImages = getImages?.filter((_img: any, i: number) => i !== index);
    let newImages2 = images.filter((_img, i: number) => i !== index);

    // Add to deletedItems if file is a URL
    if (imageToRemove.file && !imageToRemove.file.startsWith('data:')) {
      setDeletedImages((prevDeletedItems) => [
        ...prevDeletedItems,
        { ...imageToRemove, removeImage: true },
      ]);
    }

    setValue('images', newImages as never);
    setImages(() => [...newImages2]);
  };

  const removeFile = (index: number) => {
    const fileToRemove = getDocuments[index];
    let newFiles = getDocuments?.filter((_doc: any, i: number) => i !== index);

    // Add to deletedItems if file is a URL
    if (fileToRemove.file && !fileToRemove.file.startsWith('data:')) {
      setDeletedDocuments((prevDeletedItems) => [
        ...prevDeletedItems,
        { ...fileToRemove, removeDocument: true },
      ]);
    }

    setValue('documents', newFiles as never);
  };

  function getUnitPriceErrorMessage(
    currentUnitPrice: number,
    calculatedUnitPrice: number
  ) {
    if (calculatedUnitPrice < currentUnitPrice) {
      return `Unit Price cannot be less than ${currentUnitPrice}`;
    }

    return ''; // No error
  }

  function getAvailableUnitErrorMessage(
    currentAvailableUnit: number,
    calculatedAvailableUnit: number
  ) {
    if (calculatedAvailableUnit < currentAvailableUnit) {
      return `AvailableUnits cannot be less than ${currentAvailableUnit}`;
    }

    return ''; // No error
  }

  const handleAdd = () => {
    if (AvailableUnits !== undefined) {
      const newValue = ensureNumber(getAvailableUnits) + 2;
      setValue('availableUnits', newValue as never);
      setTimeout(() => {
        setAvailableUnits(newValue);
      }, 1000);
    }
  };

  const handleMinus = () => {
    if (AvailableUnits !== undefined) {
      const newValue = ensureNumber(getAvailableUnits) - 2;
      setValue('availableUnits', newValue as never);
      setTimeout(() => {
        setAvailableUnits(newValue);
      }, 1000);
    }
  };

  const handleAddROI = () => {
    if (ExpectedROI !== undefined) {
      const newValue = ensureNumber(getExpectedROI) + 1;
      setValue('expectedROI', newValue as never);
    }
  };

  const handleMinusROI = () => {
    if (ExpectedROI !== undefined) {
      const newValue = ensureNumber(getExpectedROI) - 1;
      setValue('expectedROI', newValue as never);
    }
  };

  useEffect(() => {
    const roiMultiple = ensureNumber(data?.roiMutiple);
    const maximumRoi = ensureNumber(data?.maximumRoi);

    if (getExpectedROI < roiMultiple || getExpectedROI > maximumRoi) {
      setError('expectedRoi', {
        type: 'manual',
        message: `Expected ROI must be between ${roiMultiple} and ${maximumRoi}`,
      });
    } else {
      clearErrors('expectedRoi');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExpectedROI, data?.maximumRoi, data?.roiMutiple]);

  return (
    <>
      <Form {...form}>
        <form
          className="flex flex-col gap-5"
          onSubmit={form.handleSubmit(submitForm)}
        >
          <div className="flex flex-col gap-4 space-y-4">
            <div className="flex flex-col space-y-3">
              <Label
                className={cn(
                  'text-[13px] text-[#A2A1A8] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                  { 'text-destructive': Boolean(errors.images) }
                )}
              >
                Upload Image
              </Label>
              <div className="relative">
                <Input
                  className="bg-[#F7F9FB] h-14 border-[#9AB5FF] items-start border-dashed"
                  ref={imageInput}
                  type="file"
                  accept=".jpeg, .png, .jpg"
                  onChange={(e) => imageUpload(e)}
                  multiple
                  error={Boolean(errors.images)}
                  endIcon={
                    <svg
                      width="25"
                      height="25"
                      viewBox="0 0 25 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2.26562 6.14014C2.26562 5.07927 2.68705 4.06186 3.4372 3.31171C4.18734 2.56156 5.20476 2.14014 6.26562 2.14014H18.2656C19.3265 2.14014 20.3439 2.56156 21.0941 3.31171C21.8442 4.06186 22.2656 5.07927 22.2656 6.14014V18.1401C22.2656 19.201 21.8442 20.2184 21.0941 20.9686C20.3439 21.7187 19.3265 22.1401 18.2656 22.1401H6.26562C5.20476 22.1401 4.18734 21.7187 3.4372 20.9686C2.68705 20.2184 2.26563 19.201 2.26562 18.1401V6.14014Z"
                        stroke="#4D5D6A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M8.76562 11.1401C10.1463 11.1401 11.2656 10.0208 11.2656 8.64014C11.2656 7.25942 10.1463 6.14014 8.76562 6.14014C7.38491 6.14014 6.26562 7.25942 6.26562 8.64014C6.26562 10.0208 7.38491 11.1401 8.76562 11.1401Z"
                        stroke="#4D5D6A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M14.7916 12.7612L6.26562 22.1402H18.3986C19.4242 22.1402 20.4078 21.7328 21.133 21.0076C21.8582 20.2824 22.2656 19.2988 22.2656 18.2732V18.1402C22.2656 17.6742 22.0906 17.4952 21.7756 17.1502L17.7456 12.7552C17.5578 12.5503 17.3293 12.3868 17.0748 12.2752C16.8203 12.1635 16.5452 12.1061 16.2673 12.1067C15.9894 12.1073 15.7146 12.1658 15.4605 12.2784C15.2064 12.3911 14.9786 12.5555 14.7916 12.7612V12.7612Z"
                        stroke="#4D5D6A"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                />
                <div className="grid flex-wrap items-center justify-start grid-cols-3 gap-2 sm:flex">
                  {images &&
                    images
                      ?.slice(
                        0,
                        showAllImages
                          ? images.length
                          : window.innerWidth > 500
                          ? 6
                          : 3
                      )
                      .map((image: any, i: number) => (
                        <div
                          key={i}
                          className="relative w-full sm:w-[103px] h-[86px] rounded-md bg-white"
                        >
                          <button
                            onClick={() => removeImage(i)}
                            type="button"
                            className="absolute p-1 bg-white rounded-full -top-1 -right-1"
                          >
                            <Icons.CloseIcon className="w-3 h-3" />
                          </button>
                          <img
                            src={image?.file}
                            alt={`View ${i + 1}`}
                            className="object-cover w-full h-full border rounded-md"
                          />
                        </div>
                      ))}
                  {images &&
                    images?.length > (window.innerWidth > 768 ? 6 : 3) &&
                    !showAllImages && (
                      <div
                        className="relative w-[103px] h-[86px] rounded-md bg-[#EFF6FF]"
                        onClick={() => setShowAllImages(true)}
                      >
                        <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
                          <span className="text-xs text-gray-500">
                            +
                            {images?.length - (window.innerWidth > 768 ? 6 : 3)}{' '}
                            more photos
                          </span>
                        </div>
                      </div>
                    )}
                </div>
              </div>
              {errors?.images && String(errors?.images?.message) && (
                <p className="text-xs font-medium text-destructive">
                  {String(errors?.images?.message)}
                </p>
              )}
            </div>
            <div
              className={cn('flex flex-col', {
                'gap-2': getDocuments?.length > 0,
              })}
            >
              <div className="flex flex-col gap-3">
                <Label
                  className={cn(
                    'text-[13px] text-[#A2A1A8] font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70',
                    { 'text-destructive': Boolean(errors.documents) }
                  )}
                >
                  Upload property document
                </Label>
                <div className="flex flex-col gap-2">
                  <Input
                    className="bg-[#F7F9FB] h-14 border-[#9AB5FF] items-start border-dashed"
                    ref={fileInput}
                    type="file"
                    error={Boolean(errors.documents)}
                    accept=".pdf, .docs"
                    onChange={(e) => fileUpload(e)}
                    endIcon={<FontAwesomeIcon icon={faFileLines} />}
                  />
                  <div className="z-20 flex flex-wrap items-center justify-start gap-2 mt-2">
                    {getDocuments &&
                      getDocuments.length > 0 &&
                      getDocuments?.slice(0, 4)?.map((file: any, i: number) => (
                        <div
                          key={i}
                          className="flex rounded-xl h-[56px] border p-1.5 items-center justify-between hover:bg-cta-900 sm:max-w-[200px] w-full cursor-pointer bg-white hover:scale-105 transition-all duration-300 gap-3"
                        >
                          <Link
                            to={file?.file ?? ''}
                            target="_blank"
                            className="flex items-center gap-2"
                          >
                            <span className="rounded-md w-[40px] h-[42px] flex justify-center items-center p-1 bg-carton-900">
                              <Icons.Doc />
                            </span>
                            <h1 className="w-52 sm:w-20 truncate text-xs font-medium text-[#1A1919]">
                              {`Document ${i + 1}`}
                            </h1>
                          </Link>

                          <button
                            type="button"
                            className="p-2 bg-transparent shadow-none hover:bg-zinc-100 active:bg-zinc-50"
                            onClick={() => removeFile(i)}
                          >
                            <Icons.CloseIcon className="w-4 h-3" />
                          </button>
                        </div>
                      ))}
                    {getDocuments && getDocuments?.length > 4 && (
                      <div className="relative rounded-xl p-2 w-[200px] h-[56px] bg-[#EFF6FF]">
                        <div className="absolute inset-0 flex items-center justify-center">
                          <span className="text-xs text-gray-500">
                            +{getDocuments?.length - 4} more documents
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {errors?.documents && String(errors?.documents?.message) && (
                <p className="pb-3 text-xs font-medium text-destructive">
                  {String(errors?.documents?.message)}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col justify-start gap-4 md:flex-row">
            <FormField
              name="totalAssetValue"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel> Total Asset Value</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      name="totalAssetValue"
                      value={field.value === 0 ? undefined : field.value}
                      placeholder="Enter total asset value"
                      error={Boolean(errors.availableUnits)}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              name="availableUnits"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Available (Units)</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type={'number'}
                      error={Boolean(errors.availableUnits)}
                      placeholder={'Enter available unit'}
                      endIcon={
                        <Button
                          className="h-6 p-2"
                          type="button"
                          variant="secondary"
                          onClick={handleAdd}
                        >
                          +
                        </Button>
                      }
                      startIcon={
                        <Button
                          className="h-6 p-2"
                          disabled={
                            field.value <= (data?.minimumUnit as number)
                          }
                          variant="secondary"
                          type="button"
                          onClick={handleMinus}
                        >
                          -
                        </Button>
                      }
                      className={cn(
                        'z-20 w-full h-12 outline-none ring-0 text-center'
                      )}
                    />
                  </FormControl>
                  {getAvailableUnitErrorMessage(
                    data?.minimumUnit as number,
                    field.value
                  ) && (
                    <p className="text-xs font-medium text-destructive">
                      {getAvailableUnitErrorMessage(
                        data?.minimumUnit as number,
                        field.value
                      )}
                    </p>
                  )}
                </FormItem>
              )}
            />

            <FormField
              name="unitPrice"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Unit Price</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      readOnly
                      type="number"
                      id="unitPrice"
                      placeholder="Unit price"
                      error={
                        Boolean(errors.unitPrice) ||
                        !!getUnitPriceErrorMessage(
                          data?.minimumPriceUnit as number,
                          calData?.unitPrice as number
                        )
                      }
                    />
                  </FormControl>
                  <FormMessage />
                  {getUnitPriceErrorMessage(
                    data?.minimumPriceUnit as number,
                    calData?.unitPrice as number
                  ) && (
                    <p className="text-xs font-medium text-destructive">
                      {getUnitPriceErrorMessage(
                        data?.minimumPriceUnit as number,
                        calData?.unitPrice as number
                      )}
                    </p>
                  )}
                </FormItem>
              )}
            />
          </div>

          <div className="flex flex-col items-start gap-2">
            <h1 className="text-sm text-light-grey">Investment Details (%)</h1>
            <FormField
              name="expectedROI"
              control={control}
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Expected ROI</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      id="expectedROI"
                      placeholder="Enter value"
                      className="h-12 text-center"
                      error={Boolean(errors.expectedROI)}
                      endIcon={
                        <Button
                          className="h-6 p-2"
                          type="button"
                          variant="secondary"
                          onClick={handleAddROI}
                          disabled={
                            field.value === ensureNumber(data?.maximumRoi)
                          }
                        >
                          +
                        </Button>
                      }
                      startIcon={
                        <Button
                          className="h-6 p-2"
                          disabled={
                            field.value <= ensureNumber(data?.roiMutiple)
                          }
                          variant="secondary"
                          type="button"
                          onClick={handleMinusROI}
                        >
                          -
                        </Button>
                      }
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <FormField
            control={form.control}
            name="expectedCompletion"
            render={({ field }) => (
              <FormItem className="flex flex-col">
                <FormLabel>Expected Completion</FormLabel>
                <Popover>
                  <PopoverTrigger asChild>
                    <FormControl>
                      <Button
                        variant={'outline'}
                        className={cn(
                          'w-[240px] pl-3 text-left font-normal',
                          !field.value && 'text-muted-foreground'
                        )}
                      >
                        {field.value ? (
                          format(field.value, 'PPP')
                        ) : (
                          <span>Pick a date</span>
                        )}
                        <CalendarIcon className="w-4 h-4 ml-auto opacity-50" />
                      </Button>
                    </FormControl>
                  </PopoverTrigger>
                  <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                      mode="single"
                      selected={new Date(field?.value as string)}
                      onSelect={field.onChange}
                      defaultMonth={new Date(field?.value as string)}
                      disabled={(date) => date < new Date()}
                      initialFocus
                    />
                  </PopoverContent>
                </Popover>
                <FormMessage />
              </FormItem>
            )}
          />

          {data?.isPropertyConversionAllowed && (
            <FormField
              control={form.control}
              name="isInvestimentConvertable"
              render={({ field }) => (
                <FormItem className="flex flex-row items-center space-x-3 space-y-0 ">
                  <FormControl>
                    <Checkbox
                      checked={field.value}
                      onCheckedChange={field.onChange}
                    />
                  </FormControl>
                  <FormLabel>Will investment be convertible?</FormLabel>
                </FormItem>
              )}
            />
          )}

          <div className="flex items-center gap-4 mt-4">
            <Button
              type="button"
              variant="ghost"
              className="w-full h-12 border border-border"
              onClick={() => {
                setStep(1);
                setTriggerDefaultValue(true);
              }}
              disabled={isUpdatingAsset}
            >
              Back
            </Button>
            {/* <Button
              type="button"
              variant="ghost"
              className="w-full h-12 border border-border"
              disabled={isUploadingAsset}
              onClick={() => {
                openPreview();
                onFinish({
                  ...data,
                  totalAssetValue: ensureNumber(
                    form.getValues('totalAssetValue')
                  ),
                  availableUnits: ensureNumber(
                    form.getValues('availableUnits')
                  ),
                  expectedROI: ensureNumber(form.getValues('expectedROI')),
                  unitPrice: ensureNumber(form.getValues('unitPrice')),
                  isInvestimentConvertable: form.getValues(
                    'isInvestimentConvertable'
                  ),
                });
              }}
            >
              Preview
            </Button> */}
            <Button
              disabled={calLoading || calError || isUpdatingAsset}
              type="submit"
              className="w-full h-12"
            >
              {isUpdatingAsset && (
                <Icons.Spinner className="w-5 h-5 mr-3 animate-spin" />
              )}
              {isUpdatingAsset ? 'Updating' : 'Update'}
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default StepTwo;
